<template>
  <header class="">
    <div class="max-w-screen-xl mx-auto p-1 flex">
      <router-link :to="{ name: 'CategoryIndex' }">
        <img :src="imgLogo" alt="tiengnhatit.com" class="logo" />
      </router-link>
      <div id="adsenseHeader" class="text-left w-full ml-4 flex items-end">
        <AdsenseHeader />
      </div>
    </div>

    <div class="bg-primary">
      <nav class="max-w-screen-xl mx-auto text-white p-2 flex justify-between">
        <div class="flex flex-col lg:flex-row w-full items-left lg:items-center lg:space-x-2">
          <div class="pb-0 lg:pb-1">
            <router-link :to="{ name: 'CategoryIndex' }" class="text-xl"> tiengnhatit.com </router-link>
            <button class="lg:hidden float-right text-2xl" onclick="toggleElement('#mobileNav')">
              <i class="fas fa-bars" />
            </button>
          </div>

          <div
            id="mobileNav"
            class="hidden lg:flex flex-col lg:flex-row flex-grow justify-between space-y-2 lg:space-y-0 mt-4 lg:mt-0"
          >
            <div class="flex items-center space-x-2">
              <router-link :to="{ name: 'CategoryIndex' }" class="text-md hover:bg-primary-dark transition p-2 rounded">
                <i class="fas fa-home" />
                {{ $t('menu.home') }}
              </router-link>
            </div>

            <div class="w-full lg:w-80">
              <SearchForm />
            </div>

            <div class="flex flex-col lg:flex-row items-left lg:items-center lg:space-x-2">
              <router-link :to="{ name: 'About' }" class="text-md hover:bg-primary-dark transition p-2 rounded">
                <i class="fas fa-file-alt" />
                {{ $t('menu.about') }}
              </router-link>
              <router-link :to="{ name: 'Feedback' }" class="text-md hover:bg-primary-dark transition p-2 rounded">
                <i class="fas fa-envelope" />
                {{ $t('menu.feedback') }}
              </router-link>

              <a
                id="dropdownSelectLanguage"
                class="dropdown-toggle text-md hover:bg-primary-dark transition p-2 rounded dropdown"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fas fa-globe-americas" />
                Select language
              </a>
              <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSelectLanguage">
                <li>
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item flex items-center"
                    @click="$emit('change-locale', 'vi')"
                  >
                    <img :src="imgVietnamese" alt="Vietnamese" class="w-8 mr-1" />
                    <span>Tiếng Việt</span>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item flex items-center"
                    @click="$emit('change-locale', 'ja')"
                  >
                    <img :src="imgJapanese" alt="Japanese" class="w-8 mr-1" />
                    <span>日本語</span>
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item flex items-center"
                    @click="$emit('change-locale', 'en')"
                  >
                    <img :src="imgEnglish" alt="English" class="w-8 mr-1" />
                    <span>English</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import SearchForm from '&/SearchForm.vue'
import AdsenseHeader from '&/AdsenseHeader.vue'
import imgLogo from '../../assets/images/logo.png'
import imgEnglish from '../../assets/images/flags/english.png'
import imgJapanese from '../../assets/images/flags/japanese.png'
import imgVietnamese from '../../assets/images/flags/vietnamese.png'
;['change-locale']

const data = reactive({ imgLogo, imgEnglish, imgJapanese, imgVietnamese })
</script>

<style lang="sass" scoped>
.logo
  width: 128px
  max-width: 128px
</style>
