<template>
  <div class="text-center bg-white p-8 shadow-2xl w-max mx-auto mt-12 bg-red-50">
    <h2 class="text-red-600 text-3xl">
      {{ $t('adblock.message1') }}
    </h2>

    <div class="mt-8 text-lg text-gray-600 font-bold">
      {{ $t('adblock.message2') }}
    </div>

    <img src="@/images/onegai.png" alt="Please disable Adblock" class="mx-auto mt-12" />

    <div class="mt-8 text-lg">
      {{ $t('adblock.message3') }}
    </div>
  </div>
</template>
