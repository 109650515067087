<template>
  <div class="h-full flex flex-col space-y-6">
    <section class="mt-2">
      <AdsenseAside1 />
    </section>

    <section class="sticky top-0 py-4 z-10 bg-gray-50">
      <AppHint class="">
        {{ $t('search.hint') }}
      </AppHint>
      <AppHeading class="mt-4">
        {{ $t('menu.search') }}
      </AppHeading>
      <SearchForm />
    </section>

    <section class="">
      <AdsenseAside2 />
    </section>

    <section class="">
      <AdsenseAside3 />
    </section>

    <section class="">
      <AppHeading>
        {{ $t('menu.categories') }}
      </AppHeading>
      <ul>
        <li v-for="category in wordStore.categories" :key="category.id" class="bg-white border-b border-b-gray-300">
          <router-link
            :to="{ name: 'CategoryShow', params: { slug: category.slug } }"
            class="block px-4 py-2 hover:bg-gray-200 hover:border-r-2 hover:border-primary transition flex space-x-2 items-center"
          >
            <img :src="category.image" :alt="category.name_en" class="w-6" />
            <span>{{ category['name_' + $i18n.locale] }}</span>
          </router-link>
        </li>
      </ul>
    </section>
  </div>
</template>

<script setup lang="ts">
import { useWordStore } from '%/stores/wordStore'
import SearchForm from '&/SearchForm.vue'
import AppHint from '&/AppHint.vue'
import AdsenseAside1 from '&/AdsenseAside1.vue'
import AdsenseAside2 from '&/AdsenseAside2.vue'
import AdsenseAside3 from '&/AdsenseAside3.vue'
import AppHeading from '&/AppHeading.vue'

const wordStore = useWordStore()
</script>
