<template>
  <h2 class="heading">
    <span class="heading__icon"><i class="fas fa-star" /></span>
    <slot />
  </h2>
</template>

<style lang="sass" scoped>
.heading
  position: relative
  margin: 1em 0 0.5em
  background: white
  color: dimgray
  font-weight: bold
  padding: 0.5em 2em 0.5em 4em
  font-size: 1.2em
  border-radius: 0.5em
  &__icon
    font-size: 1.2em
    position: absolute
    top: -10px
    left: 20px
    display: inline-block
    width: 46px
    height: 32px
    text-align: center
    background: #fa4141
    &::before, &::after
      position: absolute
      content: ''
    &::before
      right: -10px
      width: 0
      height: 0
      border-right: 10px solid transparent
      border-bottom: 10px solid #d90606
    &::after
      top: 50%
      left: 0
      display: block
      height: 50%
      border: 1em solid #fa4141
      border-bottom-width: 15px
      border-bottom-color: transparent
    svg
      position: relative
      z-index: 1
      color: #fff100
      margin-top: 10px
      margin-right: 0
</style>
