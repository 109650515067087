<template>
  <AppAdsense
    ad-style="display:block"
    ad-format="fluid"
    ad-layout-key="-7j+et+1n-5w+48"
    ad-client="ca-pub-9118328092882671"
    ad-slot="6015250026"
    ad-class="google-ad--top"
  />
</template>
