<template>
  <div class="flex justify-center my-4">
    <div class="pacman">
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<style lang="scss" scoped>
/*The Pacman*/
.pacman {
  position: relative;

  > div:nth-child(1),
  > div:nth-child(2) {
    width: 0;
    height: 0;
    border: 37.5px solid #be002a;
    border-right-color: transparent;
    border-radius: 50%;
    left: -15px * 4;
    animation: rotate_pacman_half_up 0.5s infinite;
    position: relative;
  }
  > div:nth-child(2) {
    animation-name: rotate_pacman_half_down;
    margin-top: -(37.5px * 2);
  }
  > div:nth-child(3),
  > div:nth-child(4),
  > div:nth-child(5) {
    animation: pacman-balls 1s infinite linear;
    background-color: #be002a;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 15px * 2;
    left: 37.5px * 2;
  }
  > div:nth-child(3) {
    animation-delay: -0.66s;
  }
  > div:nth-child(4) {
    animation-delay: -0.33s;
  }
}

/*Pacman Animation Keyframes*/
@keyframes rotate_pacman_half_up {
  0% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotate_pacman_half_down {
  0% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes pacman-balls {
  75% {
    opacity: 0.7;
  }
  100% {
    transform: translateX(-37.5px * 2.5);
  }
}
</style>
