<template>
  <div class="max-w-5xl mx-auto mt-4">
    <div class="bg-white p-4 shadow-lg">
      <h1 class="text-3xl text-primary font-bold">
        {{ $t('menu.about') }}
      </h1>

      <div class="grid grid-cols-12 gap-6 mt-8">
        <div class="col-span-12 lg:col-span-8">
          <p class="text-2xl">Xin chào các bạn</p>

          <p class="mt-4">
            Mình là Yuto, tác giả của trang web
            <b class="text-primary">tiengnhatit.com</b>
          </p>

          <p class="mt-4">
            Là 1 SE/BrSE làm việc ở Nhật Bản nên mình cũng phần nào hiểu được tiếng Nhật chuyên ngành IT phong phú và
            khó. Hơn nữa mình nghĩ là ngoài nghĩa tiếng Nhật ra thì việc biết được nghĩa gốc tiếng Anh của từ vựng cũng
            cần thiết, bởi vì nghề IT rất cần phải hiểu tiếng Anh khi code, đọc tài liệu...v.v
          </p>

          <p class="mt-4">
            Do đó mình đã tạo trang web này để tổng hợp lại tất cả những từ vựng chủ đề IT trong suốt quá trình làm việc
            và học tập. Các từ vựng được chia thành nhiều chủ đề riêng biệt, bao gồm cả tiếng Việt, tiếng Anh và tiếng
            Nhật, trong tiéng Nhật thì các từ vựng có thể bao gồm cả hiragana để thuận tiện hơn khi chúng ta chưa đọc
            được Hán tự của từ. Các từ vựng trong từng chủ đề có thể bao gồm hình ảnh minh hoạ, giải thích, và tất cả
            đều có kèm theo phần phát âm.
          </p>

          <p class="mt-4">
            Trang web cũng bao gồm chức năng tìm kiếm, cho phép tìm kiếm từ vựng dựa theo tiếng Việt (có dấu và không
            dấu), tiếng Anh, tiếng Nhật (bao gồm kanji, hiragana, katakana và cả romaji). Màn hình kết quả tìm kiểm cũng
            sẽ mô tả dầy đủ chi tiết của từ vựng.
          </p>

          <p class="mt-4">
            Về dự dịnh lâu dài thì mình sẽ bổ sung thêm các ví dụ trong từ vựng, và số lượng từ vựng sẽ được cập nhật và
            cải tiến liên tục.
          </p>

          <p class="mt-4">
            Nếu bạn nào có góp ý hoặc yêu cầu gì thì cứ thoải mái gửi
            <router-link :to="{ name: 'Feedback' }" class="link"> phản hồi </router-link>
            lại cho mình nhé.
          </p>
        </div>

        <div class="col-span-12 lg:col-span-4">
          <a href="https://yutojp.com" target="_blank" class="block hover:opacity-80 transition">
            <img src="@/images/static_pages/about/yutojp.png" alt="yutojp.com" />
          </a>
          <h3 class="text-primary text-xl text-center mt-2">
            {{ $t('about.myBlog') }}
          </h3>
        </div>
      </div>
    </div>

    <div class="bg-white my-8 p-4 shadow-lg text-gray-700">
      <h2 class="text-center text-2xl font-bold">Our team</h2>

      <div class="grid grid-cols-12 gap-8 mt-8">
        <div class="text-center col-span-6 lg:col-span-3">
          <img src="@/images/static_pages/about/member1.png" class="w-3/4 rounded-xl mx-auto mb-4" />
          <h4 class="font-bold lg:text-xl">Le Minh Thien Toan</h4>
          <div class="text-gray-600 lg:text-xl">Project Manager</div>
        </div>
        <div class="text-center col-span-6 lg:col-span-3">
          <img src="@/images/static_pages/about/member2.png" class="w-3/4 rounded-xl mx-auto mb-4" />
          <h4 class="font-bold lg:text-xl">Le Minh Thien Toan</h4>
          <div class="text-gray-600 lg:text-xl">Frontend Developer</div>
        </div>
        <div class="text-center col-span-6 lg:col-span-3">
          <img src="@/images/static_pages/about/member3.png" class="w-3/4 rounded-xl mx-auto mb-4" />
          <h4 class="font-bold lg:text-xl">Le Minh Thien Toan</h4>
          <div class="text-gray-600 lg:text-xl">Backend Developer</div>
        </div>
        <div class="text-center col-span-6 lg:col-span-3">
          <img src="@/images/static_pages/about/member4.png" class="w-3/4 rounded-xl mx-auto mb-4" />
          <h4 class="font-bold lg:text-xl">Le Minh Thien Toan</h4>
          <div class="text-gray-600 lg:text-xl">Designer</div>
        </div>
      </div>
    </div>
  </div>
</template>
